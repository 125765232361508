import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.primary.main,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus({
  data,
  value,
  disabled = false,
  onChange = () => {},
  onValueChange = () => {},
  buttonCls = '',
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const disabledEnabledCls = disabled ? 'cursor-not-allowed opacity-50' : '';
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (index) => {
    setSelectedIndex(index);
    handleClose();
    onChange(index);
    onValueChange(data[index]);
  };

  useEffect(() => {
    const idx = data.findIndex((i) => i.text == value);

    if (idx && idx > 0) {
      setSelectedIndex(idx);
    }
  }, [data, value]);

  const selected = data[selectedIndex];

  return (
    <div class="my-0 py-0">
      <div class="text-sm rounded-lg">
        <Button
          disabled={disabled}
          onClick={handleClick}
          endIcon={<ExpandMoreIcon fontSize="inherit" className="mr-2" />}
          className={`rounded-lg bg-white border-solid border-2 border-gray-300 ${selected.class} ${buttonCls} ${disabledEnabledCls}`}
        >
          {selected.icon && <div class="w-5">{selected.icon}</div>}
          <p class="text-sm font-medium ml-4 sm:mr-8 md:mr-16">{selected.text}</p>
        </Button>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="my-0 py-0"
      >
        {data.map((item, index) => {
          if (!item.text) {
            return;
          }

          return (
            <StyledMenuItem
              key={item.text}
              onClick={() => handleSelection(index)}
              className={item.class}
            >
              {item.icon && <ListItemIcon class="w-4 mr-6">{item.icon}</ListItemIcon>}
              <ListItemText primary={item.text} />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
