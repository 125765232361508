import { Button, Typography } from '@material-ui/core';
import { MobileDatePicker } from '@material-ui/pickers';
import CustomizedMenus from 'components/inputs/Menu';
import React, { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { ga, useGState } from 'state/store';
import fetchPromise from 'utils/fetch';
import { byId, formatCurrency, formatDate } from 'utils/utils';
import { a } from './invoiceReducer';

const PaymentsType = {
  CHEQUE: 'CHEQUE',
  CASH: 'CASH',
  UPI: 'UPI',
};

export function CardContainer({ twCls = '', children }) {
  return (
    <div
      className={`bg-white rounded-lg py-4 pb-6 mb-4 shadow-xs text-tertiary break-avoid ${twCls}`}
    >
      {children}
    </div>
  );
}

export function Header({ title, title2 }) {
  return (
    <div class="px-10 pt-2 pb-4 mb-6 border-solid border-b-2 border-gray-100 flex justify-between">
      <Typography class="text-lg font-medium">{title}</Typography>
      {title2}
    </div>
  );
}

export function PaymentsCard({ state, dispatch, userHasEditPermission }) {
  const {
    payments = [],
    editable,
    deliveredValue,
    damageReturn,
    paymentTerms = 'COD',
    displayState,
  } = state;
  const { allowPaymentForCredit } = useGState((s) => s[ga.CLIENT_PREFERENCE]);
  const { data: bankMaster } = useQuery(
    state?.assignment?.branchId && ['banks', state.assignment.branchId],
    () => fetchPromise(`/collection/bankMaster?branchId=${state.assignment.branchId}`)
  );

  const selectorConfig = [
    {
      icon: <img src={require('images/cash.png')} />,
      text: 'Cash',
      disabled:
        payments.some((pmt) => pmt.modeOfPayment === 'CASH') ||
        !editable ||
        state.isFailed ||
        displayState === 'PENDING',
      dispatch: () => dispatch([a.ADD_CASH]),
    },
    {
      icon: <img src={require('images/cheque.png')} />,
      text: 'Cheque',
      disabled: !editable || state.isFailed || displayState === 'PENDING',
      dispatch: () => dispatch([a.ADD_CHEQUE]),
    },
  ];

  let collected = payments
    ?.filter(
      (amt) =>
        amt?.modeDetails?.upiTrxStatus === 'success' ||
        amt.modeOfPayment === 'CASH' ||
        amt.modeOfPayment === 'CHEQUE' ||
        amt.modeOfPayment === 'SBFIN'
    )
    .reduce((acc, pmt) => acc + (pmt.amountCollected || 0), 0);

  const [selectedIdx, setSelectedIdx] = useState(0);

  function Collection({ amount }) {
    return (
      <div class="flex text-sm">
        <p class="font-medium">Collected : </p>
        <p class="font-semibold">{` ${formatCurrency(amount)} / ${formatCurrency(
          Number(deliveredValue) - (Number(damageReturn) || 0)
        )}`}</p>
      </div>
    );
  }

  // console.log({ paymentTerms });

  if (paymentTerms === 'PREPAID' || (paymentTerms === 'CREDIT' && !allowPaymentForCredit)) {
    return (
      <CardContainer>
        <Header title="Payments" title2={<Collection amount={collected} />} />
        <div class="flex flex-row py-2 px-4 items-center bg-teal rounded-lg mx-6">
          <img src={require('images/tick.png')} />
          <p class="text-white text-base ml-4">
            {paymentTerms === 'CREDIT' ? 'This is a Credit Invoice' : 'This is a Prepaid Invoice.'}
          </p>
        </div>
      </CardContainer>
    );
  }

  const renderPayments = () => {
    return payments.map((pmt) => {
      switch (pmt.modeOfPayment) {
        case 'CASH':
          return (
            <CashCard
              key={pmt.id}
              pmt={pmt}
              dispatch={dispatch}
              readOnly={
                !payments.some((pmt) => pmt.modeOfPayment === 'CASH') ||
                !editable ||
                !userHasEditPermission
              }
            />
          );
        case 'UPI':
          return <UPICard key={pmt.id} pmt={pmt} readOnly={true} />;
        case 'SBFIN':
          return <SBFINCard key={pmt.id} pmt={pmt} />;
        default:
          return (
            <ChequeCard
              key={pmt.id}
              {...{ pmt, banks: bankMaster?.banks, dispatch }}
              readOnly={!editable || !userHasEditPermission}
            />
          );
      }
    });
  };

  return (
    <CardContainer>
      <Header title="Payments" title2={<Collection amount={collected} />} />
      <div class="px-10">
        {renderPayments()}
        <div class="flex flex-row justify-between bg-background my-6 px-6 py-4 rounded-lg items-center">
          <CustomizedMenus data={selectorConfig} onChange={(val) => setSelectedIdx(val)} />
          <div>
            <Button
              className={`px-6 py-2 bg-tertiary rounded-lg text-white ${
                selectorConfig[selectedIdx].disabled ? 'cursor-not-allowed opacity-50' : ''
              }`}
              disabled={selectorConfig[selectedIdx].disabled || !userHasEditPermission}
              onClick={selectorConfig[selectedIdx].dispatch}
              startIcon={'+'}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </CardContainer>
  );
}

function CashCard({ pmt: { id, modeOfPayment, amountCollected, editable }, dispatch, readOnly }) {
  const inputRef = useRef(undefined);
  const [readOnlyInput, setReadOnlyInput] = useState(readOnly && !editable);

  const focus = () => {
    inputRef?.current.focus();
    setReadOnlyInput(false);
  };

  const handleOnChange = (value) => {
    dispatch([a.EDIT_AMOUNT, { id, value: Number(value) }]);
  };

  return (
    <PaymentModeContainer>
      <PaymentModeHeader
        id={id}
        type={modeOfPayment}
        readOnly={readOnlyInput}
        dispatch={dispatch}
        onEdit={focus}
      />
      <PaymentModeContent>
        <p class="text-xs">Amount</p>
        <div>
          <p class="inline-block mr-1">₹</p>
          <input
            readOnly={readOnlyInput}
            type="text"
            ref={inputRef}
            value={amountCollected || 0}
            class="text-base font-medium"
            onChange={(e) => (readOnlyInput ? undefined : handleOnChange(e.target.value))}
          />
        </div>
      </PaymentModeContent>
    </PaymentModeContainer>
  );
}

function SBFINCard({ pmt: { id, amountCollected, modeOfPayment } }) {
  return (
    <PaymentModeContainer>
      <PaymentModeHeader id={id} type={modeOfPayment} readOnly edit={false} noOp />
      <PaymentModeContent>
        <div class="grid grid-flow-row grid-cols-2 gap-4">
          <div>
            <p class="text-xs">Amount</p>
            <p class="text-base font-medium">{`₹ ${amountCollected || 0}`}</p>
          </div>
        </div>
      </PaymentModeContent>
    </PaymentModeContainer>
  );
}

function UPICard({ pmt: { amountCollected, id, modeDetails, modeOfPayment }, readOnly }) {
  const { upiTrxStatus, upiTrxId } = modeDetails;

  return (
    <>
      <PaymentModeContainer>
        <PaymentModeHeader id={id} type={modeOfPayment} readOnly={readOnly} edit={false} />
        <PaymentModeContent>
          <div class="grid grid-flow-row grid-cols-2 gap-4">
            <div>
              <p class="text-xs">Amount</p>
              <div>
                <input
                  type="text"
                  readOnly={readOnly}
                  value={`₹ ${amountCollected || 0}`}
                  class="text-base font-medium"
                />
              </div>
            </div>
            <div>
              <p class="text-xs">Transaction Id</p>
              <div class="text-base font-medium">
                <Typography class="break-words">{upiTrxId || 0}</Typography>
              </div>
            </div>
            <div>
              <p class="text-xs">Status</p>
              <input
                type="text"
                readOnly={readOnly}
                value={upiTrxStatus}
                class="text-base font-medium"
              />
            </div>
          </div>
        </PaymentModeContent>
      </PaymentModeContainer>
    </>
  );
}

export function PaymentModeContainer({ children }) {
  return <div class="shadow rounded-lg pb-3 my-4">{children}</div>;
}

function PaymentModeHeader(props) {
  const modeData = {
    CHEQUE: { icon: <img src={require('images/cheque.png')} />, text: 'Cheque' },
    CASH: { icon: <img src={require('images/cash.png')} />, text: 'Cash' },
    SBFIN: { icon: <img src={require('images/cash.png')} />, text: 'SB FIN' },
    UPI: { icon: <img src={require('images/cash.png')} />, text: 'Upi' },
  };

  const d = modeData[props.type];

  return (
    <div class="flex flex-row items-center justify-between bg-background px-6 py-4 rounded-lg">
      <div class="flex flex-row">
        <div class="w-6">{d?.icon}</div>
        <p class="ml-4 font-medium">{d?.text}</p>
      </div>
      {!props.noOp && <PaymentModeHeaderActions {...props} />}
    </div>
  );
}
export function PaymentModeContent({ children }) {
  return <div class="m-6">{children}</div>;
}

function PaymentModeHeaderActions(props) {
  const { id, readOnly, dispatch, type, onEdit, edit } = props;

  const disabledEnabledCls = readOnly ? 'opacity-50 cursor-not-allowed' : '';
  function Trash({ children }) {
    return !edit ? (
      <div
        class={`py-2 px-4 bg-white rounded-lg ml-4 cursor-pointer ${disabledEnabledCls}`}
        onClick={() => (readOnly ? undefined : dispatch([a.DELETE_PAYMENT, id]))}
      >
        {children}
      </div>
    ) : null;
  }

  function ChequeTrash() {
    return (
      <Trash>
        <img src={require('images/cross.png')} class="w-4" />
      </Trash>
    );
  }
  function CashTrash() {
    return (
      <Trash>
        <img src={require('images/trash.png')} class="w-4" />
      </Trash>
    );
  }

  function UPITrash() {
    return null;
  }

  function ChequeEdit() {
    return (
      <Button
        className={`px-4 py-1 bg-tertiary rounded-lg text-white ${disabledEnabledCls}`}
        startIcon="+"
        onClick={readOnly ? undefined : onEdit}
      >
        Add
      </Button>
    );
  }

  function CashEdit() {
    return (
      <div
        class={`py-2 px-4 bg-white rounded-lg cursor-pointer ${disabledEnabledCls}`}
        onClick={readOnly ? undefined : onEdit}
      >
        <img src={require('images/edit.png')} class="w-4" />
      </div>
    );
  }

  function Wrapper({ children }) {
    return <div class="flex flex-row justify-between">{children}</div>;
  }

  function Cash() {
    return (
      <Wrapper>
        <CashEdit />
        <CashTrash />
      </Wrapper>
    );
  }
  function Cheque() {
    return (
      <Wrapper>
        <ChequeEdit />
        <ChequeTrash />
      </Wrapper>
    );
  }

  function UPI() {
    return (
      <Wrapper>
        <UPITrash />
      </Wrapper>
    );
  }

  function Result() {
    const modeData = {
      [PaymentsType.CASH]: <Cash />,
      [PaymentsType.UPI]: <UPI />,
      [PaymentsType.CHEQUE]: <Cheque />,
    };
    return modeData[type];
  }

  return (
    <div class="flex flex-row justify-between">
      <Result />
    </div>
  );
}

function ChequeCard({ pmt, banks, dispatch, readOnly }) {
  const {
    id,
    amountCollected,
    editable,
    modeDetails: { chequeNo, chequeDate, bankId },
  } = pmt;

  const [readOnlyInput, setReadOnlyInput] = useState(readOnly && !editable);
  const bankOptions = useMemo(() => banks?.map((b) => ({ value: b.id, label: b.bankBranchName })), [
    banks,
  ]);

  const focus = () => {
    setReadOnlyInput(false);
  };
  return (
    <PaymentModeContainer>
      <PaymentModeHeader
        type={PaymentsType.CHEQUE}
        id={id}
        readOnly={readOnlyInput}
        dispatch={dispatch}
        onEdit={focus}
      />
      <PaymentModeContent>
        <div class="grid grid-flow-row grid-cols-2 col-gap-8 row-gap-6">
          <div class="border-solid border-b-2 border-gray-400 rounded-lg px-2">
            <p class="text-xs">Amount</p>
            <div class="flex flex-row items-center">
              <p class="inline-block mr-1">₹</p>
              <input
                readOnly={readOnlyInput}
                type="text"
                required
                value={amountCollected || 0}
                class="text-base font-medium"
                onChange={(e) =>
                  readOnlyInput
                    ? undefined
                    : dispatch([a.EDIT_AMOUNT, { id, value: Number(e.target.value) }])
                }
              />
            </div>
          </div>
          <div>
            <p class="text-xs">Bank Name</p>
            <div>
              <Select
                placeholder="Branch Name"
                value={bankOptions && byId(bankOptions, bankId, 'value')}
                options={bankOptions}
                isDisabled={readOnlyInput}
                onChange={({ value }) =>
                  dispatch([a.PAYMENT_DETAIL_EDIT, { id, key: 'bankId', value }])
                }
                disabled={readOnlyInput}
              />
            </div>
          </div>
          <div class="border-solid border-b-2 border-gray-400 rounded-lg px-2">
            <p class="text-xs">Cheque Number</p>
            <div>
              <input
                readOnly={readOnlyInput}
                type="text"
                required
                value={chequeNo}
                class="text-base font-medium"
                onChange={(e) =>
                  readOnlyInput
                    ? undefined
                    : dispatch([
                        a.PAYMENT_DETAIL_EDIT,
                        { id, key: 'chequeNo', value: e.target.value },
                      ])
                }
              />
            </div>
          </div>
          <div>
            <p class="text-xs">Date</p>
            <MobileDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              mask="__-__-____"
              value={chequeDate}
              className="border-none"
              onChange={(date) => {
                dispatch([
                  a.PAYMENT_DETAIL_EDIT,
                  { id, key: 'chequeDate', value: formatDate(date) },
                ]);
              }}
              disabled={readOnlyInput}
            />
          </div>
        </div>
      </PaymentModeContent>
    </PaymentModeContainer>
  );
}
