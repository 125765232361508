import React, { useState } from 'react';
import qs from 'query-string';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { Card, CardContent, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import fetch from 'utils/fetch';
import { a } from './invoiceReducer';
import propTypes from 'prop-types';
import { useGState, ga } from 'state/store';
import Select from 'react-select';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    width: '295px',
    // height: '170px',
    margin: '40px 94px 0 95px',
    paddingTop: '30px',
    borderRadius: '10px',
    textAlign: 'center',
  },
  successCard: {
    border: 'solid 1px #d1eeef',
    background: '#f7ffff',
  },
  successIcon: {
    height: '49px',
    width: '49px',
    color: '#00acb6',
  },
  successText: {
    color: '#00acb6',
  },
  failureCard: {
    border: 'solid 1px #fccdad',
    background: '#ffffff',
  },
  failureIcon: {
    height: '50px',
    width: '50px',
    background: '#ff7c25',
    color: 'white',
    borderRadius: '25px',
    padding: '10px',
  },
  failureText: {
    color: '#ff7c25',
  },
  otpVerification: {
    color: '#384561',
    textAlign: 'center',
    borderRadius: 0,
    boxShadow: 'none',
  },
  pinInput: {
    display: 'flex',
    margin: '0 20%',
    justifyContent: 'space-between',
    TextField: {
      textAlign: 'center',
      marginRight: '20px',
    },
  },
  otpButtons: {
    width: '130px',
    height: '54px',
    padding: '21px 25px 20px 24px',
    borderRadius: '10px',
    background: '#284466',
    color: '#ffffff',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  resendBtn: {
    background: '#e5e9ed',
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#284466',
  },
  otpSkip: {
    margin: '2% 0',
    color: '#8b93a6',
  },
  wrongOTP: {
    color: 'crimson',
  },
  btns: {
    width: '100%',
  },
});

export default function OTP({ otp, dispatch, phone }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {otp?.verified ? (
        <OTPSuccess phone={phone} />
      ) : otp?.skipped ? (
        <OTPSkipped otp={otp} dispatch={dispatch} phone={phone} />
      ) : (
        <DisplayOTP dispatch={dispatch} phone={phone} />
      )}
    </div>
  );
}

function OTPSuccess({ phone }) {
  const classes = useStyles();
  return (
    <Card className={`${classes.successCard} ${classes.card}`}>
      <CardContent>
        <CheckCircleIcon className={classes.successIcon} />
        <Typography className={classes.successText} variant="h6">
          OTP Verification Completed
        </Typography>
        <Typography>{phone}</Typography>
      </CardContent>
    </Card>
  );
}

function OTPSkipped({ otp, dispatch, phone }) {
  const classes = useStyles();

  const [skipComp, setSkipComp] = useState(false);
  const backToSkip = () => {
    setSkipComp(true);
  };

  return (
    <>
      {!skipComp ? (
        <Card className={`${classes.failureCard} ${classes.card}`}>
          <CardContent>
            <PhoneAndroidIcon className={classes.failureIcon} />
            <Typography className={classes.failureText} variant="h6">
              OTP Verification Skipped
            </Typography>
            <Typography>{otp.skipReason}</Typography>
            <Button onClick={backToSkip}>Edit</Button>
          </CardContent>
        </Card>
      ) : (
        <DisplayOTP dispatch={dispatch} phone={phone} />
      )}
    </>
  );
}

const Div = styled.div`
  width: 100%;
`;
const Input = styled.input`
  width: 40px;
  margin: 5% 0 5% 4%;
  font-size: 20px;
  text-align: center;
  border-bottom: 1px solid black;
  &:focus {
    outline: none;
  }
`;

function DisplayOTP({ dispatch, phone }) {
  const { assignmentId, invoiceId } = qs.parse(window.location.search);

  const classes = useStyles();

  let initialState = new Array(4).fill('');

  const [content, setContent] = useState(initialState);
  const [pinValue, setPinValue] = useState('');
  const elements = [];
  const [skipComponent, setSkipComponent] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  const handleChange = (e, i) => {
    content[i] = e.target.value;
    setContent(content);
    setPinValue(content.join(''));
    if (i + 1 < elements.length) {
      elements[i + 1].focus();
    }
  };

  const handleDelete = (e, i) => {
    if (e.keyCode === 8 && !e.target.value) {
      i - 1 >= 0 && elements[i - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let pin = e.clipboardData
      .getData('Text')
      .split('')
      .filter((item, i) => i < 4);
    for (let i = 0; i < pin.length; i++) {
      content[i] = pin[i];
      setContent(content);
    }

    if (pin.length < 4) {
      elements[pin.length].focus();
    }
    setPinValue(content.join(''));
  };

  const [resendOTP] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/v3/delivery/panel/resend-pod-otp?assignmentId=${assignmentId}&invoiceId=${invoiceId}`,
      }),
    {
      onSuccess: () => {
        setContent(initialState);
      },
    }
  );

  const [verifyOTP] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/v3/delivery/panel/validate-pod-otp?assignmentId=${assignmentId}`,
        data: {
          invoiceId: Number(invoiceId),
          otp: pinValue,
        },
      }),
    {
      onSuccess: (data) => {
        if (data.success) {
          dispatch([a.OTP_VERIFIED, data.success]);
        } else {
          dispatch([a.OTP_FAILURE, data.success]);
          setErrMsg(true);
          setTimeout(() => {
            setErrMsg(false);
            setContent(initialState);
          }, 2000);
        }
      },
    }
  );

  const handleClick = () => {
    verifyOTP();
  };

  const skipOTP = () => {
    setSkipComponent(true);
  };

  const cancelSkip = () => {
    setSkipComponent(false);
  };

  return (
    <>
      {!skipComponent ? (
        <Card className={classes.otpVerification}>
          <CardContent item>
            <Typography variant="h6">OTP has been sent!</Typography>
            <Typography>{phone}</Typography>
          </CardContent>
          <CardContent className={classes.pinInput}>
            <Div onPaste={handlePaste}>
              {content.map((item, i) => (
                <Input
                  key={i}
                  type="text"
                  value={content[i]}
                  onChange={(e) => handleChange(e, i)}
                  onKeyDown={(e) => handleDelete(e, i)}
                  maxLength="1"
                  ref={(n) => (elements[i] = n)}
                  disabled={errMsg ? true : false}
                />
              ))}
            </Div>
          </CardContent>
          <CardContent>
            <div>{errMsg && <div className={classes.wrongOTP}>OTP entered is wrong.</div>}</div>
          </CardContent>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            spacing={3}
            className={classes.btns}
          >
            <Grid item xs={10} sm={4}>
              <Button className={`${classes.otpButtons} ${classes.resendBtn}`} onClick={resendOTP}>
                Resend OTP
              </Button>
            </Grid>
            <Grid item xs={10} sm={4}>
              <Button className={classes.otpButtons} onClick={handleClick}>
                Verify
              </Button>
            </Grid>
            <Grid item xs={10} sm={4}>
              <Button className={`${classes.otpButtons} ${classes.resendBtn}`} onClick={skipOTP}>
                Skip
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <SkipOTP cancel={cancelSkip} dispatch={dispatch} />
      )}
    </>
  );
}

DisplayOTP.propTypes = {
  length: propTypes.number,
};

function SkipOTP({ cancel, dispatch }) {
  const classes = useStyles();

  const skipPodOTPReasons = useGState((s) => s[ga.SKIP_POD_OTP_REASONS]);

  const onFailReason = (reason) => {
    dispatch([a.OTP_FAILURE_REASON, reason.value]);
  };

  return (
    <Card className={classes.otpVerification}>
      <CardContent className="h-56 w-64">
        <Typography align="left" variant="h6" className={classes.otpSkip}>
          Reason for OTP Skip
        </Typography>
        <Select
          options={skipPodOTPReasons?.map((reason) => ({ value: reason, label: reason }))}
          onChange={(value) => onFailReason(value)}
          autoFocus={true}
        />
      </CardContent>
      <Grid container>
        <Grid item xs={12}>
          <Button className={`${classes.otpButtons} ${classes.resendBtn}`} onClick={cancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
