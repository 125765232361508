import React, { useState } from 'react';
import { IconButton, CircularProgress, ButtonBase } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseRounded';
import { useMutation } from 'react-query';
import fetchPromise from 'utils/fetch';

export default function ImageUploader({ images: initialImages = [], id, onChange, disabled }) {
  const [localImages, setLocalImages] = useState([]);

  const [uploadImage] = useMutation(
    ({ data }) =>
      fetchPromise({
        method: 'POST',
        url: `/v2/client/image`,
        data: data,
      }),
    {
      onSuccess: ({ imageUrl }, { localImageUri }) => {
        setLocalImages((images) => images.filter((img) => img !== localImageUri));
        onChange([...initialImages, imageUrl]);
      },
    }
  );

  const handleImageUpload = (event) => {
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fReader = new FileReader();
      fReader.readAsDataURL(file);
      fReader.onloadend = async () => {
        const blob = await (await fetch(fReader.result)).blob();
        var formData = new FormData();
        formData.append('file', blob);
        uploadImage({ data: formData, localImageUri: fReader.result });
        setLocalImages((imgs) => [...imgs, fReader.result]);
      };
    }
  };

  function removeImage(url) {
    onChange(initialImages.filter((item) => item !== url));
  }

  return (
    <div>
      <div
        class="grid gap-4 mt-3"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        }}
      >
        {[...initialImages, ...localImages]?.map((image) => (
          <div key={image} class="relative" style={{ paddingBottom: '100%' }}>
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img src={image} class="absolute h-full w-full object-cover rounded-lg" />
            </a>
            {!disabled &&
              (initialImages.includes(image) ? (
                <IconButton
                  class="absolute right-0 -m-2 bg-gray-200 rounded-full"
                  component="span"
                  onClick={() => removeImage(image)}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <CircularProgress class="absolute right-0 -m-2 text-success" size={24} />
              ))}
          </div>
        ))}

        <div
          class="relative rounded-lg"
          style={{
            ...(!localImages.length && !initialImages.length
              ? { width: '150px', height: '150px' }
              : { paddingBottom: '100%' }),
          }}
        >
          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            id={`image-file-${id}`}
            // disabled={readOnly}
            // multiple
          />
          {!disabled && (
            <label htmlFor={`image-file-${id}`}>
              <ButtonBase
                class="absolute h-full w-full border-gray-400 border-solid border-2 bg-secondary rounded-lg flex justify-center"
                component="span"
              >
                <img src={require('images/photo.png')} class="h-10 w-10 place-self-center" />
              </ButtonBase>
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
