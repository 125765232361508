import { AppBar, Box, IconButton, LinearProgress, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import SKUImageIcon from '@material-ui/icons/LocalMallRounded';
import ButtonM from 'components/inputs/ButtonM';
import CustomizedMenus from 'components/inputs/Menu';
import { navigate } from 'components/Link';
import qs from 'query-string';
import React, { useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { useGState } from 'state/store';
import { useSnackbar } from 'utils/customHooks';
import fetchPromise from 'utils/fetch';
import { format0, format2, formatDateHumanReadable, isVal } from 'utils/utils';
import invoiceReducer, { a, getDeliveryState, getPayload } from './invoiceReducer';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CardContainer, Header, PaymentsCard } from './Payments';
import Pricing from './Pricing';
import ProofOfAction from './ProofOfAction';
import ReasonForAction from './ReasonForAction';

export default function Invoice({ location }) {
  const { assignmentId, retailerId, invoiceId } = qs.parse(location.search);
  const permissions = useGState((s) => s.permission);
  const userHasEditPermission = permissions.some((p) => p && p.code === 'tms.execution.edit');
  const {
    branch,
    clientPreference: { enablePod },
  } = useGState((s) => ({ branch: s.branch, clientPreference: s.clientPreference }));

  const [notif] = useSnackbar();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [state, dispatch] = useReducer(invoiceReducer, {
    isDirty: false,
    pricing: { quantityChanged: false },
  });

  const { data } = useQuery(
    ['invoice', { assignmentId, retailerId, invoiceId }],
    (_, { assignmentId, retailerId, invoiceId }) =>
      fetchPromise(
        `/v3/delivery/panel/invoice?assignmentId=${assignmentId}&retailerId=${retailerId}&invoiceId=${invoiceId}`
      ),
    {
      onSuccess: (data) => dispatch([a.INIT, data]),
    }
  );

  const [saveInvoiceAPI] = useMutation(
    () =>
      fetchPromise({
        url: `/v3/delivery/panel/invoice/state?assignmentId=${assignmentId}`,
        method: 'PUT',
        data: getPayload(state),
      }),
    {
      onMutate: () => setDisableSubmit(true),
      onSuccess: () => {
        notif('Submitted successfully', { variant: 'success' });
        dispatch([a.SAVED]);
      },
      onSettled: () => setDisableSubmit(false),
    }
  );

  const isPickup = state.type === 'PICKUP';

  const isInCompleteCheque = () => {
    return state?.payments
      .filter((pmt) => pmt.modeOfPayment === 'CHEQUE')
      .map((i) =>
        (({ amountCollected, modeDetails: { bankId, chequeNo, chequeDate } }) => ({
          amountCollected,
          bankId,
          chequeNo,
          chequeDate,
        }))(i)
      )
      .some(
        (pmt) => !(Number(pmt.amountCollected) > 0 && pmt.bankId && pmt.chequeNo && pmt.chequeDate)
      );
  };
  const showIncompleteChequeError = () => {
    const error = 'Cheque details are not completed!';
    notif(error, { variant: 'error' });
  };

  function saveInvoice() {
    if (isInCompleteCheque()) {
      showIncompleteChequeError();
      return;
    }
    saveInvoiceAPI();
  }
  if (!data) return <LinearProgress color="secondary" />;

  return (
    <>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() =>
              navigate(
                `/dl/retailerAssignment?assignmentId=${assignmentId}&retailerId=${retailerId}`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Box display="flex" justifyContent="space-between" width={1}>
            <Box p={1}>
              <Typography variant="h6">{data?.code}</Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div class="px-5 sm:px-10 md:px-10">
        <div class="mb-6 mt-8 flex justify-between md:pr-20">
          <div class="flex flex-col md:flex-row items-start">
            <BackButton
              onClick={() =>
                navigate(
                  `/dl/retailerAssignment?assignmentId=${assignmentId}&retailerId=${retailerId}`
                )
              }
            />
            <div class="text-lg font-medium md:ml-6 mt-4 md:mt-0">
              <p>{data?.code}</p>
              <p class="text-sm opacity-75">{branch?.name}</p>
            </div>
          </div>
          <DeliveryStateAndFailureSelector
            state={state}
            isPickup={isPickup}
            dispatch={dispatch}
            userHasEditPermission={userHasEditPermission}
          />
        </div>
        <GridContainer>
          <LineItemsComponent
            lineItems={state?.lineItems}
            dispatch={dispatch}
            srnValue={state?.srnValue || 0}
            isPickup={isPickup}
            displayState={state?.displayState}
            userHasEditPermission={userHasEditPermission}
          />
          {!isPickup && (
            <Pricing
              state={state}
              dispatch={dispatch}
              isChanged={false}
              userHasEditPermission={userHasEditPermission}
            />
          )}
          {!['FAILED'].includes(state.state) && !isPickup && (
            <PaymentsCard
              state={state}
              dispatch={dispatch}
              userHasEditPermission={userHasEditPermission}
            />
          )}
          {/* <ReturnedItemsCard lineItems={state?.lineItems} isReturned={Boolean(state?.srnValue)} /> */}
          <ReturnedItemsCard lineItems={state?.lineItems} isReturned />
          {['PARTIAL_DELIVERED', 'FAILED'].includes(getDeliveryState(state)) && (
            <ReasonForAction
              isFailed={state.isFailed}
              disabled={!state.editable || !userHasEditPermission}
              initialData={{ images: state.images, messages: state.messages }}
              onChange={(data) => dispatch([a.REASONS, data])}
            />
          )}
          {['PARTIAL_DELIVERED', 'DELIVERED'].includes(state.state) && (
            <ProofOfAction
              initialData={state.pod}
              otpEnabled={state.isPodOTPEnabled}
              imageEnabled={enablePod?.image?.enabled}
              phone={state.phoneMasked}
              dispatch={dispatch}
              disabled={!state.editable || !userHasEditPermission}
              isPickup={isPickup}
              onChange={(pod) => dispatch([a.POD, pod])}
            />
          )}
        </GridContainer>
        <SaveInvoice
          disabled={
            !state.isDirty ||
            state.pricing.quantityChanged ||
            disableSubmit ||
            state.displayState === 'PENDING' ||
            !userHasEditPermission
          }
          onClick={saveInvoice}
        />
      </div>
    </>
  );
}

export function BackButton({ onClick, padding = 'px-4 py-2', shadow = true }) {
  return (
    <button
      class={`flex-none bg-white ${padding} ${
        shadow ? 'shadow' : 'border border-1 border-solid border-gray-200'
      } rounded-lg`}
      onClick={onClick}
    >
      <ArrowBackIcon />
    </button>
  );
}

export const deliveryStateOptions = (supplier, isPickup, displayState) => {
  const options = [
    supplier
      ? { text: 'PICKED', class: 'text-success' }
      : isPickup
      ? { text: 'PICKED UP', class: 'text-success' }
      : { text: 'DELIVERED', class: 'text-success' },
    { text: 'FAILED', class: 'text-danger' },
  ];
  if (displayState === 'PENDING') options.push({ text: 'PENDING' });
  return options;
};
export const getFailureOptions = (enableRetrys) => {
  const options = [
    { text: '' },
    { text: 'SRN', class: 'bg-pink-600 text-white' },
    { text: 'HOLD', class: 'bg-teal text-white' },
  ];
  if (enableRetrys) {
    options.push({ text: 'RETRY', class: 'bg-green-600 text-white' });
  }
  return options;
};

export function DeliveryStateAndFailureSelector(props) {
  const {
    clientPreference: { enableRetrys },
  } = useGState((s) => ({ clientPreference: s.clientPreference }));

  const { state, isPickup, dispatch, userHasEditPermission } = props;

  return (
    <div class="flex flex-col items-end md:flex-row">
      {state.displayState ? (
        <CustomizedMenus
          value={state.displayState}
          disabled={!state?.editable || !userHasEditPermission}
          buttonCls="py-3 rounded-lg"
          data={deliveryStateOptions(state.supplier, isPickup, state.displayState)}
          onValueChange={(item) => dispatch([a.STATE, item.text])}
        />
      ) : null}
      {state.isFailed && !isPickup && (
        <CustomizedMenus
          value={state.returnState}
          disabled={!state.editable || !userHasEditPermission}
          buttonCls="py-3 mt-4 md:mt-0 md:ml-3 rounded-lg"
          data={getFailureOptions(enableRetrys)}
          onValueChange={(item) => dispatch([a.RETURN_STATE, item.text])}
        />
      )}
    </div>
  );
}

export function GridContainer({ children }) {
  return <div class="md:px-20 col-count-1 lg:col-count-2 max-w-full">{children}</div>;
}

function LineItemsComponent({
  lineItems,
  srnValue,
  dispatch,
  isPickup,
  displayState,
  userHasEditPermission,
}) {
  return (
    <CardContainer>
      <Header title="Items" />
      {lineItems?.map((lineItem) => (
        <LineItem
          key={lineItem.id}
          srnValue={srnValue}
          lineItem={lineItem}
          dispatch={dispatch}
          isPickup={isPickup}
          displayState={displayState}
          userHasEditPermission={userHasEditPermission}
        />
      ))}
    </CardContainer>
  );
}

const useStyles = makeStyles(() => ({
  qty: {
    width: '5rem',
  },
}));

function LineItem(props) {
  const classes = useStyles();
  const { lineItem, dispatch, isPickup, displayState, userHasEditPermission } = props;
  const { id, deliveredQuantity, quantity, isChanged, reductionFactor } = lineItem;
  const qty = isVal(deliveredQuantity) ? deliveredQuantity : quantity;

  const [quantityOfItems, setQuantityOfItems] = useState(qty);

  useEffect(() => {
    setQuantityOfItems(qty);
  }, [displayState, qty]);

  const onQtyChange = (e) => {
    setQuantityOfItems(e.value);
    dispatch([a.SKU_QTY, { id, qty: e.value }]);
    // dispatch([a.SRN_VALUE, srnValue + qtyToAddOrRemove * price]);
  };

  let quantityOptions = [{ value: quantity, label: quantity }];

  while (quantityOptions[quantityOptions.length - 1].value - reductionFactor > 0) {
    let optionValue = Number(quantityOptions[quantityOptions.length - 1].value) - reductionFactor;
    quantityOptions.push({ value: optionValue, label: optionValue });
  }
  quantityOptions.push({ value: 0, label: 0 });

  return (
    <div class="flex px-10 justify-between text-start">
      <LineItemDetails lineItem={lineItem} qty={qty} isChanged={isChanged} isPickup={isPickup} />
      {isPickup || displayState !== 'DELIVERED' || !userHasEditPermission ? (
        <input
          type="text"
          class="border border-gray-300 rounded-md text-center h-11 w-1/5"
          value={qty}
          readOnly
        />
      ) : (
        <Select
          value={quantityOptions.filter(function (option) {
            return option.value === quantityOfItems;
          })}
          options={quantityOptions}
          onChange={onQtyChange}
          className={classes.qty}
        />
      )}
    </div>
  );
}

function ReturnedItemsCard({ lineItems, isReturned }) {
  const returnedItems = lineItems?.filter((it) => it.quantity > it.deliveredQuantity);

  if (!isReturned || !returnedItems?.length) return <></>;

  return (
    <CardContainer>
      <Header title="Returned Products" />
      {returnedItems.map((lineItem) => {
        const returnQty = (lineItem.quantity || 0) - (lineItem.deliveredQuantity || 0);

        return (
          <div class="flex px-10 justify-between text-start" key={lineItem.id}>
            <LineItemDetails lineItem={lineItem} qty={returnQty} />
            <div class="flex flex-row items-start">
              <p>{returnQty}</p>
            </div>
          </div>
        );
      })}
    </CardContainer>
  );
}

function LineItemDetails({ lineItem, qty, isChanged = false, isPickup }) {
  const { skuName, price, skuImages = [], details } = lineItem;

  return (
    <div class="mb-4 flex">
      {skuImages?.[0] ? (
        <img class="w-10 h-10 mr-4 md:mr-8" src={skuImages[0]} />
      ) : (
        <SKUImageIcon class="w-10 h-10 mr-4 md:mr-8" />
      )}
      <div class="mr-4">
        <p class="text-xs font-medium break-all">{skuName}</p>
        {isPickup ? (
          <>
            {details?.mrp && <p class="text-tiny opacity-50">{`MRP: ${details?.mrp}`}</p>}
            {details?.manufacturedDate && (
              <p class="text-tiny opacity-50">{`Manufacturing Date: ${formatDateHumanReadable(
                details?.manufacturedDate
              )}`}</p>
            )}
            {details?.expiryDate && (
              <p class="text-tiny opacity-50">{`Expiry Date: ${formatDateHumanReadable(
                details?.expiryDate
              )}`}</p>
            )}
          </>
        ) : isChanged ? (
          <p class="text-tiny text-warn">Quantity changed</p>
        ) : (
          <p class="text-tiny opacity-50">{`₹${format2(price)} * ${qty} = ₹${format0(
            price * qty
          )}`}</p>
        )}
      </div>
    </div>
  );
}

// function IncrementalBox({ value, maxValue, onChange, isPickup }) {
//   return (
//     <div class="flex flex-row justify-between items-center pl-5 pr-1 py-1 border-solid border-2 border-gray-300 rounded-lg cursor-pointer">
//       <p class="mr-4">{value}</p>
//       {!isPickup && (
//         <div class="flex flex-col font-medium">
//           <div
//             class="flex  hover:bg-gray-300"
//             onClick={() => value < maxValue && onChange(Math.min(value + 1, maxValue), -1)}
//           >
//             <ExpandLessIcon fontSize="inherit" />
//           </div>
//           <div
//             class="flex  hover:bg-gray-300"
//             onClick={() => value > 0 && onChange(Math.max(value - 1, 0), 1)}
//           >
//             <ExpandMoreIcon fontSize="inherit" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

function SaveInvoice({ disabled, onClick }) {
  // const colorCls = disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer';

  return (
    <div class="flex py-8 sm:px-10 px-6 md:mx-20 shadow mt-10 bg-white rounded-lg justify-end">
      <ButtonM onClick={onClick} disabled={disabled}>
        Submit
      </ButtonM>
    </div>
  );
}
