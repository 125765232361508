import { Box, LinearProgress, Slide, Slider } from '@material-ui/core';
import NoSsr from '@material-ui/core/NoSsr';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { navigate } from 'components/Link';
import Maps from 'components/Maps';
import markerGreen from 'images/markerGreen.png';
import markerGrey from 'images/markerGrey.png';
import markerRed from 'images/markerRed.png';
import markerYellow from 'images/markerYellow.png';
import markerEnd from 'images/trackingMarkers/end.png';
import wareHouse from 'images/warehouse.png';
import qs from 'query-string';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { ReactQueryConfigProvider, useQuery } from 'react-query';
import { useGState } from 'state/store';
import { useKeyPress } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { getLatLng, mapStyles } from 'utils/mapsUtils';
import { formatDistance, formatTime, truncate } from 'utils/utils';
import { BackButton } from './Invoice';

const convertDecToTime = (dec) => {
  let hour = Math.floor(dec);
  let decPart = dec - hour;
  let min = 1 / 60;
  decPart = min * Math.round(decPart * 60);
  let minute = Math.floor(decPart * 60) + '';
  if (minute.length < 2) minute = '0' + minute;
  var time = hour + ':' + minute;
  return time;
};

const getTime = (d) => {
  const date = new Date(d);
  return date.getHours() + date.getMinutes() / 100;
};

function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date1 - date2);
  let diffInHrs = diffInMs / (1000 * 60 * 60);
  diffInHrs = Math.round(diffInHrs * 100) / 100;
  return diffInHrs;
}

export default ({ assignmentId }) => {
  // const [pState, setPState] = useState({ deliveryAgentName: '' });
  const { branchId } = qs.parse(location.search);
  const branch = useGState((s) => s.branches.find((b) => b.id == branchId));
  const goBack = () => {
    navigate(`/dl/retailers/${assignmentId}`);
  };
  return (
    <>
      <NoSsr>
        <ReactQueryConfigProvider config={{ suspense: true }}>
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Box>
              <Maps
                render={() => (
                  <AssignmentTracking
                    branch={branch}
                    assignmentId={assignmentId}
                    // setPState={setPState}
                    goBack={goBack}
                  />
                )}
              />
            </Box>
          </Suspense>
        </ReactQueryConfigProvider>
      </NoSsr>
    </>
  );
};

const AssignmentTracking = (props) => {
  const { assignmentId, branch, goBack } = props;
  const mapRef = useRef();
  const shiftPressed = useKeyPress('Shift');
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [zoom, setZoom] = useState(8);
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [timeSelected, setTimeSelected] = useState(undefined);
  const [retailerSelected, setRetailerSelected] = useState({});

  const {
    data: {
      dashboard: { distanceTravelled, timeInMarket },
      retailers,
      deliveryAgent,
      softEvents = [],
      vehicle,
    } = {},
  } = useQuery(assignmentId !== 'undefined' && `delivery_retailers_tracking${assignmentId}`, () =>
    fetch(`/delivery/assignment/${assignmentId}/dashboard`)
  );
  const softEventsArray = useMemo(() => Object.values(softEvents), [softEvents]);

  const getMinMax = () => {
    const maxDate = new Date(softEventsArray[softEventsArray.length - 1].timestamp);
    const minDate = new Date(softEventsArray[0].timestamp);
    return {
      min: getTime(minDate),
      diff: getDifferenceInHours(maxDate, minDate),
      startDate: minDate,
    };
  };
  // useEffect(() => {
  //   if (softEventsArray.length > 0) setTimeSelected(getMinMax().max);

  //   setPState({ deliveryAgentName: deliveryAgent.name || deliveryAgent.loginId });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deliveryAgent]);

  useEffect(() => {
    if (softEventsArray) {
      const filteredTrackingPoints = softEventsArray.filter(
        (p) =>
          timeSelected === undefined ||
          getDifferenceInHours(new Date(p.timestamp), getMinMax().startDate) <=
            timeSelected - getMinMax().min
      );
      setTrackingPoints(filteredTrackingPoints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softEventsArray, timeSelected]);

  const getIcon = (state) => {
    switch (state) {
      case 'DELIVERED':
        return markerGreen;
      case 'PARTIAL_DELIVERED':
        return markerYellow;
      case 'FAILED':
        return markerRed;
      case 'NOT_VISITED':
      default:
        return markerGrey;
    }
  };

  const goToStore = () => {
    navigate(
      `/dl/retailerAssignment?assignmentId=${assignmentId}&retailerId=${retailerSelected.id}`
    );
  };
  const retailersArray = useMemo(() => Object.values(retailers), [retailers]);

  const google = useMemo(() => window.google, []);
  const bounds = useMemo(() => {
    let bounds = new google.maps.LatLngBounds();

    retailersArray.forEach((r) => {
      if (r.latitude && r.longitude) bounds.extend(getLatLng(r));
    });

    if (branch?.locations?.[0])
      bounds.extend({ lat: branch.locations[0].latitude, lng: branch.locations[0].longitude });
    trackingPoints.forEach((point) => bounds.extend(getLatLng(point)));

    return bounds;
  }, [branch, google.maps.LatLngBounds, retailersArray, trackingPoints]);

  const renderRetailers = () => {
    const retailerMarkers = retailersArray.map((retailer) => {
      if (retailer.latitude && retailer.longitude) {
        return (
          <Marker
            key={retailer.id}
            position={{ lat: retailer.latitude, lng: retailer.longitude }}
            clickable
            icon={{ url: getIcon(retailer.state), scaledSize: new google.maps.Size(27, 43) }}
            onClick={(e) => setRetailerSelected({ ...retailer, ...e.pixel })}
          />
        );
      }
    });
    return retailerMarkers;
  };

  const renderPolyline = () => {
    let path = new google.maps.MVCArray();
    trackingPoints.forEach((event) => {
      path.push(new google.maps.LatLng(event.latitude, event.longitude));
    });
    return (
      <>
        <Polyline
          options={{
            strokeOpacity: 1,
            strokeWeight: 0.6,
          }}
          path={path}
        />
        {trackingPoints.length && (
          <>
            <Marker
              position={{ lat: trackingPoints[0].latitude, lng: trackingPoints[0].longitude }}
              clickable
              icon={{ url: wareHouse, scaledSize: new google.maps.Size(50, 25) }}
            />
            <Marker
              position={{
                lat: trackingPoints[trackingPoints.length - 1].latitude,
                lng: trackingPoints[trackingPoints.length - 1].longitude,
              }}
              clickable
              icon={{ url: markerEnd, scaledSize: new google.maps.Size(25, 30) }}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <GoogleMap
        id="plan-polishing"
        zoom={zoom}
        mapContainerStyle={{
          height: '94vh',
          width: '100%',
        }}
        onLoad={(map) => {
          mapRef.current = map;
          map.fitBounds(bounds);
          map.mapTypes.set(
            'OSM',
            new google.maps.ImageMapType({
              getTileUrl: function (coord, zoom) {
                return (
                  'https://tile.openstreetmap.org/' + zoom + '/' + coord.x + '/' + coord.y + '.png'
                );
              },
              tileSize: new google.maps.Size(256, 256),
              name: 'OSM',
              maxZoom: 18,
            })
          );
        }}
        bounds={bounds}
        options={{
          styles: mapStyles,
          streetViewControl: false,
          draggable: shiftPressed ? false : true,
          scaleControl: false,
          mapTypeId,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        onMapTypeIdChanged={() => {
          if (mapRef.current) setMapTypeId(mapRef.current.mapTypeId);
        }}
      >
        <div class="absolute top-8 left-5 w-11/12">
          <div class="flex flex-col md:flex-row justify-between">
            <RetailerCard deliveryAgent={deliveryAgent} goBack={goBack} vehicle={vehicle} />
            {trackingPoints.length && (
              <SliderCard
                timeSelected={timeSelected}
                setTimeSelected={setTimeSelected}
                min={getMinMax().min}
                diff={getMinMax().diff}
              />
            )}
            <MapListCard goBack={goBack} selectedIndex={1} />
          </div>
        </div>
        <div class="absolute bottom-36 right-0 lg:bottom-20 lg:right-8">
          <ZoomControl setZoom={(val) => setZoom((zoom) => zoom + val)} />
          <MetricCard
            title="Travelled"
            value={`${distanceTravelled ? formatDistance(distanceTravelled.value) : 0} Kms`}
            src={require('images/road.png')}
          />
          <MetricCard
            title="Time Spent"
            value={timeInMarket?.value ? formatTime(timeInMarket.value / 1000) : formatTime(0)}
            src={require('images/time.png')}
          />
        </div>
        {branch?.locations?.[0] && (
          <Marker
            position={{ lat: branch.locations[0].latitude, lng: branch.locations[0].longitude }}
          />
        )}
        {renderRetailers()}
        {renderPolyline()}
        {Object.keys(retailerSelected).length != 0 && (
          <InfoWindowDialogSlide
            retailer={retailerSelected}
            setRetailerSelected={setRetailerSelected}
            goToStore={goToStore}
          />
        )}
      </GoogleMap>
      {/* {renderMetricsFooter()} */}
    </>
  );
};

function InfoWindowDialogSlide(props) {
  const { retailer, setRetailerSelected, goToStore } = props;
  let color = 'gray';
  if (retailer?.state) {
    if (retailer?.state === 'DELIVERED') color = 'green';
    else if (retailer?.state === 'PARTIAL_DELIVERED') color = 'yellow';
    else if (retailer?.state === 'FAILED') color = 'red';
  }
  const handleClose = () => {
    setRetailerSelected({});
  };

  return (
    <Slide direction="up" in={Boolean(retailer)} mountOnEnter unmountOnExit>
      <div
        style={{ top: '80vh' }}
        class={`flex flex-row relative mx-auto justify-start bg-white rounded-lg lg:w-1/3 md:w-1/2 xs:w-full`}
      >
        <div
          class={`bg-white rounded-lg p-3 py-1 shadow h-full border-l-8 border-${color}-500 w-full flex justify-between items-center`}
        >
          <div class="flex flex-col justify-between">
            <p class="text-inactive font-medium text-sm"> {retailer.code}</p>
            <p class="font-medium text-md">{truncate(retailer.name)}</p>
          </div>
          <button
            type="button"
            class="border border-solid border-gray-200 self-start mt-5 px-5 py-2 rounded-lg font-medium text-tertiary"
            onClick={goToStore}
          >
            Go to Store
            <span class="ml-3">
              <ArrowRightAltIcon />
            </span>
          </button>
        </div>

        <button type="button" onClick={handleClose}>
          <img class="absolute top-3 right-3 w-3" src={require('images/cross.png')} />
        </button>
      </div>
    </Slide>
  );
}

function RetailerCard(props) {
  const { goBack, vehicle, deliveryAgent } = props;
  return (
    <div class="py-4 px-8 mb-5 md:mb-0 border shadow bg-white rounded-lg hidden lg:block">
      <div class="flex flex-row justify-between items-start">
        <BackButton shadow={false} onClick={goBack} />
        <div class="ml-5  text-inactive">
          <p class="font-semibold text-xl text-tertiary mb-1 tracking-wider">
            {deliveryAgent.name}
          </p>
          <span>
            <LocalShippingOutlinedIcon />
            <span class="ml-2 tracking-wider">{vehicle.name || vehicle.vehicleType}</span>
          </span>
          <span>{` | ${vehicle.vehicleNo}`}</span>
        </div>
      </div>
    </div>
  );
}
export function MapListCard(props) {
  const { goBack, selectedIndex = 0, goToMap } = props;

  return (
    <div class="flex flex-row self-start mt-5 md:mt-0 items-center justify-between bg-white p-2 rounded-lg">
      <button
        type="button"
        class={`flex flex-row items-center px-3 py-2 ${
          selectedIndex == 0 ? 'bg-dark_blue' : ''
        } rounded-lg`}
        onClick={goBack}
      >
        <img class="h-4 mr-2" src={require('images/map-copy.png')} />
        <p class={`text-lg font-semibold ${selectedIndex === 0 ? 'text-white' : 'text-inactive'}`}>
          List View
        </p>
      </button>
      <button
        type="button"
        class={`flex flex-row items-center ${
          selectedIndex == 1 ? 'bg-dark_blue' : ''
        } px-3 py-2 rounded-lg`}
        onClick={goToMap}
      >
        <img class="h-4 mr-2" src={require('images/map.png')} />
        <p class={`text-lg font-semibold ${selectedIndex == 1 ? 'text-white' : 'text-inactive'}`}>
          Map View
        </p>
      </button>
    </div>
  );
}
function SliderCard(props) {
  const { setTimeSelected, min, diff } = props;
  const [sliderVal, setSliderVal] = useState(min);
  const handleChange = (event, value) => {
    setTimeSelected(value);
    setSliderVal(value);
  };
  return (
    <div class="self-start flex flex-row items-center justify-center w-11/12 lg:w-1/3 bg-white rounded-lg px-2 pt-4 text-tertiary font-medium">
      <span class="mr-3">{convertDecToTime(min)}</span>
      <Slider
        value={sliderVal}
        onChange={handleChange}
        valueLabelDisplay="on"
        valueLabelFormat={convertDecToTime(sliderVal % 24)}
        step={0.01}
        min={min}
        max={diff + min}
        classes={{ colorPrimary: 'text-light_blue' }}
      />
      <span class="ml-5">{convertDecToTime((diff + min) % 24)}</span>
    </div>
  );
}
function MetricCard(props) {
  const { title, value, src } = props;
  return (
    <div class="mt-5 shadow bg-white rounded-lg px-10 py-6 hidden md:block ">
      <div class="flex flex-col items-center">
        <img class="w-8 mb-3" src={src} />
        <p class="text-xl font-medium text-tertiary">{value}</p>
        <p class="font-medium text-sm text-inactive uppercase">{title}</p>
      </div>
    </div>
  );
}

function ZoomControl(props) {
  const { setZoom } = props;

  return (
    <div class="flex flex-row-reverse">
      <div class="flex flex-col shadow bg-white px-3 py-1 mr-5 text-3xl">
        <button class="border-solid border-b-2" onClick={() => setZoom(1)}>
          <p>+</p>
        </button>
        <button onClick={() => setZoom(-1)}>
          <p>-</p>
        </button>
      </div>
    </div>
  );
}
