import React from 'react';
import ImageUploader from './ImageUploader';
import OTP from './OTP';
import { CardContainer, Header } from './Payments';

export default function ProofOfAction({
  initialData,
  onChange,
  disabled,
  isPickup,
  dispatch,
  otpEnabled,
  imageEnabled,
  phone,
}) {
  return (
    <CardContainer>
      <Header title={`Proof of ${isPickup ? 'Pickup' : 'Delivery'}`}></Header>
      {imageEnabled ? (
        <div className="px-10">
          <ImageUploader
            images={initialData?.images}
            id="proof"
            disabled={disabled}
            onChange={(images) => onChange({ images })}
          />
        </div>
      ) : null}
      {otpEnabled ? <OTP otp={initialData.otp} dispatch={dispatch} phone={phone} /> : null}
    </CardContainer>
  );
}
