import { Router } from '@reach/router';
import Layout from 'components/Layout';
import DeliveryAssignmentTracking from 'delivery/execution/DeliveryAssignmentTracking';
import DeliveryRetailers from 'delivery/execution/DeliveryRetailers';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';

export default (props) => (
  <Layout {...props} hideDateFilter>
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Router>
        <DeliveryRetailers path="/dl/retailers/:assignmentId" />
        <DeliveryAssignmentTracking path="/dl/retailers/tracking/:assignmentId" />
      </Router>
    </ReactQueryConfigProvider>
  </Layout>
);
