import Text from 'components/Text';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { ga, useGState } from 'state/store';
import ImageUploader from './ImageUploader';
import { CardContainer, Header } from './Payments';

export default function ReasonForAction({ isFailed, initialData, onChange, disabled }) {
  const { failureReasons, partialReasons } = useGState((s) => ({
    failureReasons: s[ga.FAILURE_REASONS],
    partialReasons: s[ga.PARTIAL_FAILURE_REASONS],
  }));

  const options = useMemo(
    () => (isFailed ? failureReasons : partialReasons)?.map((value) => ({ value, label: value })),
    [isFailed, failureReasons, partialReasons]
  );

  if (!options?.length) return null;

  return (
    <CardContainer>
      <Header
        title={isFailed ? 'Reason for Failed Invoice' : 'Reason for Partial Delivery'}
      ></Header>
      <div className="px-10">
        <Select
          options={options}
          isMulti={!isFailed}
          isDisabled={disabled}
          value={initialData.messages?.map((value) => ({ value, label: value }))}
          onChange={(data) => {
            onChange({
              images: initialData.images,
              messages: isFailed ? [data.value] : data.map((m) => m.value),
            });
          }}
        ></Select>
        <Text class="text-sm font-bold mt-5">Upload Photos</Text>
        <ImageUploader
          images={initialData.images}
          id="reason"
          disabled={disabled}
          onChange={(images) => onChange({ images, messages: initialData.messages })}
        />
      </div>
    </CardContainer>
  );
}
