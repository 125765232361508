import produce from 'immer';
import { byId, isVal, remove, uuid } from 'utils/utils';

const invoiceReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    if (action !== a.INIT) draft.isDirty = true;
    switch (action) {
      case a.INIT:
        return {
          DATA_LOADED: true,
          isFailed: data?.state === 'FAILED',
          editable: ['COLLECTING', 'ACKNOWLEDGED'].includes(data?.assignmentState),
          displayState: getDisplayState(data).state,
          originalInvoiceData: data,
          pricing: {
            quantityChanged: false,
            quantityWasChanged: false,
          },
          ...data,
          payments: [...data.payments, ...data.sbpayPayments],
        };

      case a.SKU_QTY:
        {
          const lineItem = byId(draft.lineItems, data.id);
          lineItem.deliveredQuantity = data.qty;
          lineItem.isChanged = true;
          draft.pricing.quantityChanged = true;
        }
        break;

      case a.PRICING_SUCCESS:
        data.lineItems.forEach(({ lineItemId, value }) => {
          const lineItem = byId(draft.lineItems, lineItemId);
          lineItem.value = value;
          lineItem.isChanged = false;
        });
        draft.deliveredValue = data.value;
        draft.pricing.quantityChanged = false;
        draft.pricing.quantityWasChanged = true;
        break;

      case a.PRICING_RESET:
        draft.lineItems = draft.originalInvoiceData.lineItems;
        draft.deliveredValue = draft.originalInvoiceData.deliveredValue;
        draft.pricing.quantityChanged = false;
        break;

      case a.DELIVERED_VALUE:
        draft.deliveredValue = data;
        break;

      case a.STATE:
        draft.displayState = data;
        draft.isFailed = data === 'FAILED';
        draft.state = getDeliveryState({
          ...state,
          displayState: data,
          isFailed: data === 'FAILED',
        });
        //resetting pricing car
        draft.lineItems = draft.originalInvoiceData.lineItems;
        draft.lineItems.forEach((li) => (li.deliveredQuantity = undefined));
        draft.deliveredValue = state.value;
        draft.pricing.quantityChanged = false;
        // resetting reasons for action
        draft.messages = [];
        draft.images = [];

        break;

      case a.STATE_REASON:
        draft.stateMessage = data;
        break;

      case a.RETURN_STATE:
        draft.returnState = data;
        break;

      case a.SAVED:
        draft.isDirty = false;
        break;

      case a.ADD_CASH:
        draft.payments.push({
          id: uuid(),
          modeOfPayment: 'CASH',
          amountCollected: 0,
          editable: true,
        });
        break;

      case a.ADD_CHEQUE:
        draft.payments.push({
          id: uuid(),
          modeOfPayment: 'CHEQUE',
          amountCollected: 0,
          editable: true,
          modeDetails: {
            bankId: null,
            chequeNo: null,
            chequeDate: null,
          },
        });
        break;

      case a.DELETE_PAYMENT:
        draft.payments = remove(draft.payments, data, 'id');
        break;

      case a.EDIT_AMOUNT:
        byId(draft.payments, data.id).amountCollected = data.value;
        break;

      case a.PAYMENT_DETAIL_EDIT:
        byId(draft.payments, data.id).modeDetails[data.key] = data.value;
        break;

      case a.REASONS:
        draft.messages = data.messages;
        draft.images = data.images;
        break;

      case a.POD:
        draft.pod = data;
        break;

      case a.POD_EXTRA:
        if (!state.pod) draft.pod = {};
        if (!state.pod.extraFields) draft.pod.extraFields = {};
        draft.pod.extraFields[data.key] = data.value;
        break;

      case a.POD_IMG:
        if (!state.pod) draft.pod = {};
        if (!state.pod.images) draft.pod.images = [];
        draft.pod.images.push(...data);
        draft.image_upload = false;
        draft.pod_images = undefined;
        break;
      case a.TEMP_POD_IMG:
        draft.image_upload = true;
        if (!state.pod_images) draft.pod_images = [];
        draft.pod_images.push(data);
        break;

      case a.DAMAGE_RETURN:
        draft.damageReturn = data;
        break;

      case a.SRN_VALUE:
        draft.srnValue = data;
        break;

      case a.OTP_VERIFIED:
        draft.pod.otp = {
          verified: data,
          skipped: false,
          skipReason: '',
        };
        break;

      case a.OTP_FAILURE:
        draft.pod.otp = {
          verified: data,
          skipped: false,
          skipReason: '',
        };
        break;

      case a.OTP_FAILURE_REASON:
        draft.pod.otp = {
          verified: false,
          skipReason: data,
          skipped: true,
        };
        break;

      default:
        return state;
    }
  });
};

export function getPayload(state) {
  const payload = [
    {
      invoiceId: state.id,
      state: getDeliveryState(state),
      payments: state.payments.map((pmt) => ({
        amount: Number(pmt.amountCollected),
        mode: pmt.modeOfPayment,
        details: pmt.modeDetails,
      })),
      lineItemUpdates: state.lineItems.map((lineItem) => {
        const deliveredQuantity = isVal(lineItem.deliveredQuantity)
          ? lineItem.deliveredQuantity
          : lineItem.quantity;
        return {
          lineItemId: lineItem.id,
          deliveredQuantity,
          deliveredValue: lineItem.deliveredValue,
        };
      }),
      damageReturn: Number(state.damageReturn),
      // srnValue: Number(state.srnValue),
      deliveredValue: state.deliveredValue,
      images: state.images,
      messages: state.messages,
      pod: state.pod,
    },
  ];

  if (state.isFailed) {
    payload[0].stateReason = state.stateMessage;
    payload[0].returnStatus = state.returnState;
    payload[0].lineItemUpdates = [];
  }

  return payload;
}

export function getDeliveryState(state) {
  if (state.isFailed) return 'FAILED';
  if (!state.displayState) return state.displayState;

  return state.lineItems.some(({ quantity, deliveredQuantity }) => quantity - deliveredQuantity)
    ? 'PARTIAL_DELIVERED'
    : 'DELIVERED';
}

export function getDisplayState({ state, supplier }) {
  if (state === 'FAILED' || !state) return { state: state || 'PENDING', isPartial: false };
  return { state: supplier ? 'PICKED' : state, isPartial: state === 'PARTIAL_DELIVERED' };
}

export const a = {
  INIT: 'INIT',
  ADD_CASH: 'ADD_CASH',
  ADD_CHEQUE: 'ADD_CHEQUE',
  DELETE_PAYMENT: 'DELETE_PAYMENT',
  DELIVERED_VALUE: 'DELIVERED_VALUE',
  EDIT_AMOUNT: 'EDIT_AMOUNT',
  PAYMENT_DETAIL_EDIT: 'PAYMENT_DETAIL_EDIT',
  STATE_REASON: 'STATE_REASON',
  SKU_QTY: 'SKU_QTY',
  STATE: 'STATE',
  SAVED: 'SAVED',
  POD_EXTRA: 'POD_EXTRA',
  POD_IMG: 'POD_IMG',
  TEMP_POD_IMG: 'TEMP_POD_IMG',
  DAMAGE_RETURN: 'DAMAGE_RETURN',
  SRN_VALUE: 'SRN_VALUE',
  REASONS: 'REASONS',
  POD: 'POD',
  PRICING_SUCCESS: 'PRICING_SUCCESS',
  PRICING_RESET: 'PRICING_RESET',
  RETURN_STATE: 'RETURN_STATE',
  OTP_VERIFIED: 'OTP_VERIFIED',
  OTP_FAILURE: 'OTP_FAILURE',
  OTP_FAILURE_REASON: 'OTP_FAILURE_REASON',
};

export default invoiceReducer;
