import { Box, Divider, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ButtonM from 'components/inputs/ButtonM';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useSettings } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { formatCurrency } from 'utils/utils';
import { a } from './invoiceReducer';
import { CardContainer } from './Payments';

const useStyles = makeStyles(() => ({
  updatePrice: {
    color: 'white',
    background: '#0eb2a9',
    borderRadius: '7px',
    padding: '0.575rem 1.238rem',
    margin: '0 0 0 0.875rem',
  },
  cancel: {
    color: '#0eb2a9',
    fontFamily: 'Rubik',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontStretch: 'normal',
    textAlign: 'center',
    margin: 'auto',
    cursor: 'pointer',
  },
  damagedAmt: {
    background: '#99a7b1',
    color: 'white',
    padding: '0.563rem 1.313rem 0.625rem 1rem',
    borderRadius: '6px',
  },
}));

export default function Pricing({ state, dispatch, userHasEditPermission }) {
  const {
    deliveredValue,
    value,
    state: invoiceState,
    id,
    lineItems,
    damageReturn,
    displayState,
    pricing: { quantityChanged, quantityWasChanged },
  } = state;

  const classes = useStyles();
  const { editDeliveryCollectibleAmount } = useSettings();

  const [getNewPrice] = useMutation(
    () =>
      fetch({
        url: '/v3/delivery/panel/cart-value',
        method: 'POST',
        data: {
          invoiceId: id,
          lineItems: lineItems.map((li) => ({
            lineItemId: li.id,
            quantity: li.deliveredQuantity ?? li.quantity,
          })),
        },
      }),
    {
      onSuccess: (resp) => {
        if (resp.status === 'SUCCESS') {
          dispatch([a.PRICING_SUCCESS, resp]);
        }
      },
    }
  );

  const [priceEditable, setPriceEditable] = useState(false);
  const [damageEditable, setDamageEditable] = useState(false);

  useEffect(() => {
    setPriceEditable(false);
    setDamageEditable(false);
  }, [displayState]);

  return (
    <CardContainer>
      <div class="px-10 pt-2 pb-4 mb-6 border-solid border-b-2 border-gray-100 flex justify-between">
        <Typography class="text-lg font-medium">Pricing</Typography>
        {/* {editPricing && (
          <Box className="flex">
            <Typography className={classes.cancel} onClick={cancelEdit}>
              Cancel
            </Typography>
            <Button className={classes.updatePrice}>Update Price</Button>
          </Box>
        )} */}
        {quantityChanged && (
          <div>
            <ButtonM variant="text" class="mr-4" onClick={() => dispatch([a.PRICING_RESET, {}])}>
              Reset
            </ButtonM>
            <ButtonM onClick={() => getNewPrice()}>Get New Price</ButtonM>
          </div>
        )}
      </div>
      <div class="px-10 flex flex-row justify-between">
        <p class="text-sm font-medium">Invoice Amount</p>
        {priceEditable && displayState === 'DELIVERED' ? (
          <TextField
            value={deliveredValue}
            variant="outlined"
            size="small"
            type="number"
            onChange={(e) => {
              dispatch([a.DELIVERED_VALUE, Number(e.target.value)]);
            }}
          />
        ) : (
          <div class="flex items-center">
            <p class="text-xl font-medium">
              {formatCurrency(invoiceState === 'FAILED' ? value : deliveredValue)}
            </p>
            {editDeliveryCollectibleAmount && displayState === 'DELIVERED' && quantityWasChanged ? (
              <EditIcon
                style={{ cursor: 'pointer', marginLeft: '0.75rem' }}
                onClick={() => setPriceEditable(true)}
              />
            ) : null}
          </div>
        )}
      </div>
      <div class="mt-4 mx-10">
        {(damageEditable || damageReturn) && displayState === 'DELIVERED' ? (
          <>
            <Box class="flex justify-between items-center">
              <div class="flex items-center">
                <CloseIcon
                  style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                  className="text-danger"
                  onClick={() => {
                    dispatch([a.DAMAGE_RETURN, 0]);
                    setDamageEditable(false);
                  }}
                />
                <p class="text-sm font-medium">Damaged Amount</p>
              </div>
              <TextField
                value={damageReturn || 0}
                variant="outlined"
                size="small"
                type="number"
                inputProps={{
                  style: { textAlign: 'right' },
                }}
                onChange={(e) => {
                  dispatch([a.DAMAGE_RETURN, Number(e.target.value)]);
                }}
              />
            </Box>
            {damageReturn ? (
              <>
                <Divider class="my-4" />
                <Box class="flex justify-between">
                  <Typography>Collectable Amount</Typography>
                  <Typography class="font-medium text-lg">
                    {formatCurrency(Number(deliveredValue) - Number(damageReturn))}
                  </Typography>
                </Box>
              </>
            ) : null}
          </>
        ) : (
          editDeliveryCollectibleAmount &&
          displayState === 'DELIVERED' && (
            <button
              className={classes.damagedAmt}
              onClick={() => setDamageEditable(true)}
              disabled={!userHasEditPermission}
            >
              <p class="text-sm font-medium">Add Damaged amount</p>
            </button>
          )
        )}
      </div>
    </CardContainer>
  );
}
