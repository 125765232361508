import React from 'react';
import ButtonM from '@material-ui/core/Button';

export default function Button({ children, ...rest }) {
  return (
    <ButtonM variant={rest.variant || 'contained'} color="primary" disableElevation {...rest}>
      {children}
    </ButtonM>
  );
}
