import React from 'react';

function Container({ children, cls = 'px-20' }) {
  return (
    <div
      style={{
        minHeight: '100%',
        backgroundColor: '#f1f3f7',
      }}
      className={cls}
    >
      {children}
    </div>
  );
}

export default Container;
