import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CheckIcon from '@material-ui/icons/Check';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Container from 'components/Container';
import { navigate } from 'components/Link';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useGState } from 'state/store';
import { useAPI, useBranchId } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { formatDateHumanReadable, formatDayTime } from '../../utils/utils';
import { MapListCard } from './DeliveryAssignmentTracking';

import { useMutation } from 'react-query';

export default ({ assignmentId }) => {
  const permissions = useGState((s) => s.permission);
  const userHasUnapprovePermission = permissions.some(
    (p) => p && p.code === 'tms.execution.unapprove'
  );
  const userHasEditPermission = permissions.some((p) => p && p.code === 'tms.execution.edit');

  const [pState, setPState] = useState({
    anchorEl: null,
    deliveryAgentName: '',
    approveSuccess: false,
    enableActivate: false,
  });
  const [fetchApi, , loading] = useAPI();
  const [returnStates, setReturnStates] = useState({});
  const nodeId = useBranchId();

  const { data: retailerData, refetch } = useQuery(
    assignmentId !== 'undefined' && 'delivery_retailers',
    () => fetch(`/delivery/assignment/${assignmentId}/retailer`)
  );

  const [fetchDockDoors, { data: dockDoors }] = useMutation(() =>
    fetch(`/wms/storage/dockdoor/dashboard?whId=${nodeId}`)
  );

  const approveAssignment = (data, onSuccess = () => {}, onFailure = () => {}) => {
    fetchApi(
      {
        method: 'POST',
        url: `/v4/delivery/approveAssignment?assignmentId=${assignmentId}`,
        data: data,
      },
      onSuccess,
      onFailure
    );
  };

  const [activateAssignment, { isLoading: isLoadingActivate }] = useMutation(
    () =>
      fetch({
        url: `/v4/delivery/activate?assignmentId=${assignmentId}`,
        method: 'POST',
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const [activateWHR, { isLoading: isLoadingWHR }] = useMutation(
    (dockdoorId) =>
      fetch({
        url: `/wms-cl-returns/session`,
        params: {
          assignmentId,
          nodeId,
          dockdoorId,
        },
        method: 'POST',
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleReturnChange = (retailerId, returnStatus) => {
    fetchApi(
      {
        method: 'PUT',
        url: `/v3/delivery/assignment/${assignmentId}/return`,
        data: { retailerId, returnStatus },
      },
      () =>
        setReturnStates((prevState) => ({
          ...prevState,
          [retailerId]: returnStatus,
        })) // success
    );
  };

  return retailerData ? (
    <DeliveryRetailers
      retailerData={retailerData}
      assignmentId={assignmentId}
      setPState={setPState}
      pState={pState}
      dockDoors={dockDoors}
      approveAssignment={approveAssignment}
      activateWHR={activateWHR}
      activateAssignment={activateAssignment}
      handleReturnChange={handleReturnChange}
      returnStates={returnStates}
      fetchDockDoors={fetchDockDoors}
      loading={loading || isLoadingActivate || isLoadingWHR}
      userHasUnapprovePermission={userHasUnapprovePermission}
      userHasEditPermission={userHasEditPermission}
    />
  ) : (
    <LinearProgress />
  );
};

const initialFilterState = {
  DELIVERED: {
    label: 'Success',
    value: true,
    color: '#0eb27e',
    number: (retailers) => retailers.filter((item) => item.state === 'DELIVERED').length,
  },
  PARTIAL_DELIVERED: {
    label: 'Partial',
    value: true,
    color: '#f6af0d',
    number: (retailers) => retailers.filter((item) => item.state === 'PARTIAL_DELIVERED').length,
  },
  FAILED: {
    label: 'Failed',
    value: true,
    color: '#fa677b',
    number: (retailers) => retailers.filter((item) => item.state === 'FAILED').length,
  },
  NOT_VISITED: {
    label: 'Pending',
    value: true,
    color: '#828ea8',
    number: (retailers) => retailers.filter((item) => item.state === 'NOT_VISITED').length,
  },
};

function DeliveryRetailers({
  retailerData,
  assignmentId,
  setPState,
  pState,
  approveAssignment,
  activateAssignment,
  handleReturnChange,
  returnStates,
  activateWHR,
  fetchDockDoors,
  dockDoors,
  loading,
  userHasUnapprovePermission,
  userHasEditPermission,
}) {
  const classes = useStyles();
  const {
    clientPreference: { xdockEnabled },
  } = useGState((s) => ({ clientPreference: s.clientPreference }));

  const {
    approved,
    activated,
    lmrCompleted,
    retailers,
    deliveryAgent,
    vehicle,
    picklist,
    isWMSClReturnable,
    isTripWMSClReturnsActivated,
    isTripWMSClReturnable,
    isInventoryApprovable,
  } = retailerData;

  // const {
  //   data: { approved, activated, lmrCompleted, retailers, deliveryAgent, vehicle, picklist } = {},
  //   refetch,
  // } = useQuery(assignmentId !== 'undefined' && 'delivery_retailers', () =>
  //   fetch(`/delivery/assignment/${assignmentId}/retailer`)
  // );

  const [deliveryFilters, setDeliveryFilters] = useState(initialFilterState);

  const handleApprove = () => {
    approveAssignment({ approved: true }, () =>
      setPState((preState) => ({ ...preState, approveSuccess: true }))
    );
  };

  const handleFilterChange = (event) => {
    const key = event.target.name;
    const newFilters = {
      ...deliveryFilters,
      [key]: {
        label: deliveryFilters[key].label,
        value: event.target.checked,
        color: deliveryFilters[key].color,
        number: deliveryFilters[key].number,
      },
    };
    setDeliveryFilters(newFilters);
  };

  useEffect(() => {
    if (assignmentId === 'undefined') return;

    setPState({
      deliveryAgentName: deliveryAgent.name || deliveryAgent.loginId,
      approveSuccess: approved,
    });
  }, [
    deliveryAgent,
    xdockEnabled,
    assignmentId,
    setPState,
    lmrCompleted,
    activated,
    approved,
    retailers,
  ]);

  useEffect(() => {
    if (assignmentId === 'undefined') return;
    setPState((preState) => ({
      ...preState,
      enableActivate: getEnableActivate(xdockEnabled, activated, retailers),
    }));
  }, [assignmentId, xdockEnabled, activated, retailers, setPState]);

  const handleClose = () => {
    setPState((preState) => ({ ...preState, anchorEl: null }));
  };

  const [dockDoorId, setDockDoorId] = useState();

  const handleUnApprove = () => {
    approveAssignment({ approved: false }, () =>
      setPState((preState) => ({
        ...preState,
        approveSuccess: false,
      }))
    );
    handleClose();
  };

  const [dialog, setDialog] = useState(false);
  const [dockdoorDialog, setDockdoorDialog] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
    setDockdoorDialog(false);
    setMenu(false);
  };

  const closeAndUnApprove = () => {
    setDialog(false);
    handleUnApprove();
    setMenu(false);
  };

  const [menu, setMenu] = useState(false);

  const openMenu = (event) => {
    setMenu(!menu);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenu(false);
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Container>
      <Box className={classes.outerBox}>
        <Box className="flex justify-between">
          <Box className="flex my-auto">
            <IconButton className={classes.backArrow} onClick={() => navigate('/dl/execution')}>
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Typography variant="h6">
                {pState.deliveryAgentName} (Trip: {picklist.name}{' '}
                {formatDateHumanReadable(picklist.date)})
              </Typography>
              <Box>
                <IconButton>
                  <LocalShippingIcon />
                  <Typography className="ml-3">
                    {vehicle.vehicleType} | {vehicle.vehicleNo}
                  </Typography>
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className="flex my-auto">
            <MapListCard
              selectedIndex={0}
              goToMap={() => navigate(`/dl/retailers/tracking/${assignmentId}`)}
            />
          </Box>
        </Box>
        {assignmentId === 'undefined' ? (
          <Box m={2}>
            <Typography>Picklist not assigned. Assign the picklist First.</Typography>
          </Box>
        ) : (
          <Box className="bg-white rounded-xl">
            <Box className={classes.appbar}>
              <Typography className="my-auto">{retailers.length} Customers</Typography>
              <Box className={classes.filter} top={48}>
                <FormGroup row>
                  {deliveryFilters &&
                    Object.keys(deliveryFilters).map((key) => {
                      return (
                        <Box
                          key={key}
                          className={
                            deliveryFilters[key].value
                              ? `${classes[deliveryFilters[key].label]} ${classes.filterCard}`
                              : `${classes.uncheck} ${classes.filterCard}`
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleFilterChange}
                                checked={deliveryFilters[key].value}
                                style={
                                  deliveryFilters[key].value
                                    ? { color: `${deliveryFilters[key].color}` }
                                    : { color: 'black' }
                                }
                                name={key}
                              />
                            }
                            label={
                              <Typography style={{ fontSize: '0.7rem' }}>
                                {deliveryFilters[key].label} :{' '}
                                {deliveryFilters[key].number(retailers)}
                              </Typography>
                            }
                          />
                        </Box>
                      );
                    })}
                </FormGroup>
              </Box>
            </Box>
            <Box mb={7} pb={7} mr={2} ml={2}>
              <Grid container alignItems="stretch">
                {retailers
                  ?.filter((ret) => deliveryFilters[ret.state]?.value)
                  .map((retailer) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={retailer.id}
                      // component={Box}
                      // className={classNames(classes[retailer.state])}
                    >
                      <RetailerCard
                        retailer={retailer}
                        assignmentId={assignmentId}
                        handleReturnChange={handleReturnChange}
                        returnStates={returnStates}
                        loading={loading}
                        approved={pState.approveSuccess || loading}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        )}
        <Box className={classes.footer}>
          {isWMSClReturnable ? (
            <Button
              className={isTripWMSClReturnsActivated ? classes.activateSuccess : classes.activate}
              variant="contained"
              color="primary"
              disabled={
                !isTripWMSClReturnable ||
                loading ||
                isTripWMSClReturnsActivated ||
                !userHasEditPermission
              }
              onClick={() => {
                setDockdoorDialog(true);
                fetchDockDoors();
              }}
            >
              {isTripWMSClReturnsActivated ? 'WHR Activated' : 'Activate WHR'}
            </Button>
          ) : null}

          {xdockEnabled ? (
            <Button
              className={activated ? classes.activateSuccess : classes.activate}
              variant="contained"
              color="primary"
              disabled={!pState.enableActivate || loading || !userHasEditPermission}
              onClick={activateAssignment}
            >
              {activated ? 'LMR Activated' : 'LMR Activate'}
            </Button>
          ) : null}

          <Button
            className={pState.approveSuccess ? classes.success : undefined}
            variant="contained"
            color="primary"
            disabled={
              !isInventoryApprovable || loading || pState.approveSuccess || !userHasEditPermission
            }
            onClick={handleApprove}
          >
            <CheckIcon /> {pState.approveSuccess ? 'Approved' : 'Approve'}
          </Button>
          <div className="px-2 py-2 bg-white rounded-lg">
            <IconButton
              disabled={!pState.approveSuccess}
              aria-controls="unapprove-menu"
              aria-haspopup="true"
              onClick={openMenu}
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              id="unapprove-menu"
              anchorEl={anchorEl}
              keepMounted
              open={menu}
              onClose={closeMenu}
            >
              <MenuItem onClick={openDialog} disabled={!userHasUnapprovePermission}>
                Unapprove
              </MenuItem>
            </Menu>
          </div>
        </Box>
        <Dialog
          open={dialog}
          onClose={closeDialog}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Unapprove
          </DialogTitle>
          <DialogContent>
            <DialogContentText>To unapprove, click on the Unapprove.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={closeAndUnApprove} color="primary">
              Unapprove
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dockdoorDialog}
          onClose={closeDialog}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Select Dock Door
          </DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="dockDoors"
                name="dockDoors"
                onChange={(e) => setDockDoorId(e.target.value)}
              >
                {dockDoors
                  ?.filter((d) => d.active)
                  .map((dd) => (
                    <FormControlLabel
                      key={dd.id}
                      value={dd.id}
                      control={<Radio />}
                      label={dd.code}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                activateWHR(dockDoorId);
                closeDialog();
              }}
              color="primary"
              variant="contained"
              disabled={!dockDoorId || loading}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
}

function RetailerCard({ retailer, assignmentId }) {
  const classes = useStyles();
  const {
    id,
    code,
    name,
    state,
    index,
    address,
    returnStates,
    compliant,
    dbUpdatedAt,
    actionRequired,
  } = retailer;
  // const enableRetrys = useGState((s) => s.clientPreference.enableRetrys);
  // const options = getDeliveryOptions(enableRetrys);

  return (
    <>
      <Box
        display="flex"
        m={1}
        p={1}
        px={2}
        w={1}
        borderRadius={6}
        boxShadow={1}
        borderLeft={6}
        className={classNames(classes[state])}
      >
        <Box className="flex">
          <Box className="relative">
            <Avatar variant="square" src={retailer?.images[0]} className="rounded" />
            <Box maxWidth={1 / 3} className="rounded-sm absolute top-0 bg-white text-black px-1">
              <Typography color="textSecondary" align="center">
                {index + 1}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ cursor: 'pointer' }}
            className="ml-2"
            onClick={() =>
              navigate(`/dl/retailerAssignment/?assignmentId=${assignmentId}&retailerId=${id}`)
            }
          >
            <div style={{ width: '5rem' }} className="relative">
              <Typography variant="h6" noWrap>
                {code}
                <span className="absolute -right-8">
                  {compliant === true ? (
                    <Avatar src={require('images/correctLoc.png')} alt="true" />
                  ) : compliant === false ? (
                    <Avatar src={require('images/wrongLoc.png')} alt="false" />
                  ) : null}
                </span>
              </Typography>
            </div>
            <Typography>{name}</Typography>
            <div style={{ width: '8rem' }}>
              <Typography variant="caption" component="p" noWrap>
                {address[0]}
              </Typography>
            </div>
          </Box>
        </Box>

        {/* <Box>
          {state === 'FAILED' && (
            <Box minWidth={2 / 5} align="right">
              <CustomSelect
                value={returnStates[id] || returnStatus}
                items={options}
                disabled={approved}
                style={{ fontSize: '0.4rem', minWidth: '80%' }}
                onChange={(event) => {
                  event.stopPropagation();
                  handleReturnChange(id, event.target.value);
                }}
              />
            </Box>
          )}
        </Box> */}

        <Box>
          <Box className="flex flex-col mr-2 gap-1">
            <Typography>{formatDayTime(new Date(dbUpdatedAt))}</Typography>
            {state === 'FAILED' && (
              <>
                <Box className="flex flex-col gap-1">
                  {returnStates?.map((item) => (
                    <Box className={`${classes.failedReason} ${classes[item]}`} key={item}>
                      {item}
                    </Box>
                  ))}
                </Box>

                {actionRequired && <Box className={classes.actionRequired}>Action Required</Box>}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

// const getEnableApprove = ({ xdockEnabled, lmrCompleted, activated, approved, status = [] }) => {
//   const enableApproveValidList = {
//     DELIVERED: true,
//     PARTIAL_DELIVERED: true,
//     FAILED: { HOLD: true, RETRY: true, SRN: true },
//   };
//   const isValid = (state, returnStatus) => {
//     const val = enableApproveValidList[state];

//     if (val === undefined) return false;

//     if (typeof val === 'object') return val[returnStatus];
//     else return val;
//   };
//   const isNotValidState = status.some((item) => !isValid(item.state, item.returnStatus));

//   return xdockEnabled
//     ? lmrCompleted && activated && !(approved || isNotValidState)
//     : !(approved || isNotValidState);
// };

function getEnableActivate(xDockEnabled, activated, retailers) {
  const enableActivateValidList = {
    DELIVERED: true,
    PARTIAL_DELIVERED: true,
    FAILED: { HOLD: true, RETRY: true, SRN: true },
  };
  const isValid = (state, returnStatus) => {
    const val = enableActivateValidList[state];

    if (val === undefined) return false;

    if (typeof val === 'object') return val[returnStatus];
    else return val;
  };

  const isNotValidState = retailers.some((item) => !isValid(item.state, item.returnStatus));

  const isActionRequired = retailers.some((item) => item.actionRequired);

  return xDockEnabled && !(activated || isNotValidState) && !isActionRequired;
}

const useStyles = makeStyles(() => ({
  outerBox: {
    padding: '1rem 0',
    width: '100%',
    justifyContent: 'space-between',
    background: '#f1f3f7',
  },
  backArrow: {
    width: '3.5rem',
    height: '3.313rem',
    margin: '0.56rem 1.688rem 2.938rem 0rem',
    padding: '1.313rem 1.188rem 1.375rem 1.375rem',
    borderRadius: '8px',
    border: 'solid 1px #d5d9e3',
    background: 'white',
  },
  DELIVERED: {
    borderColor: '#0eb27e',
    // borderLeft: '6px solid #0eb27e',
    paddingY: '2rem',
    justifyContent: 'space-between',
  },
  PARTIAL_DELIVERED: {
    borderColor: amber[500],
    // borderLeft: `6px solid ${amber[500]}`,
    paddingY: '2rem',
    justifyContent: 'space-between',
  },
  FAILED: {
    borderColor: red[500],
    // borderLeft: `6px solid ${red[500]}`,
    paddingY: '2rem',
    justifyContent: 'space-between',
  },
  NOT_VISITED: {
    borderColor: '#8d99b2',
    // borderLeft: '6px solid #8d99b2',
    paddingY: '2rem',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: 'white',
    position: 'sticky',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
    backgroundColor: 'transparent',
    width: '100%',
  },
  success: {
    background: '#0eb27e !important',
    color: 'white !important',
  },

  activate: {
    marginRight: 5,
  },

  activateSuccess: {
    background: '#0eb27e !important',
    color: 'white !important',
    marginRight: 5,
  },

  filterCard: {
    width: '10.038rem',
    textAlign: 'center',
    height: '3.138rem',
    fontSize: '1rem',
    fontFamily: 'Rubik',
    fontWeight: 900,
    borderRadius: '8px',
    margin: '0.225rem 0.938rem 0',
    padding: '0.275rem 1.138rem 0.875rem 1.125rem',
  },
  Success: {
    border: 'solid 1px #c7e3da',
    background: '#f5fffc',
    color: '#0eb27e',
  },
  Partial: {
    border: 'solid 1px #f6af0d',
    background: '#fffbf3',
    color: '#f6af0d',
  },
  Failed: {
    border: 'solid 1px #ffc1c9',
    background: '#fffdfd',
    color: '#fa677b',
  },
  Pending: {
    border: 'solid 1px #d1d6e1',
    background: '#fafcff',
    color: '#828ea8',
  },
  uncheck: {
    border: 'solid 1px #eceef2',
    background: 'white',
    color: 'black',
  },
  failedReason: {
    padding: '0.3rem',
    textAlign: 'center',
    borderRadius: '8px',
  },
  appbar: {
    display: 'flex',
    background: 'white',
    borderTopLeftRadius: '10rem',
    borderTopRightRadius: '10rem',
    justifyContent: 'space-between',
    padding: '1.438rem 2.75rem 1.438rem 2.688rem',
    boxShadow: '0 6px 9px 0 rgba(168, 177, 189, 0.11)',
    margin: '0 0 3.063rem',
    height: '100%',
  },
  actionRequired: {
    background: '#fa677b',
    color: 'white',
    padding: '0.375rem 0.688rem 0.438rem 0.875rem',
    borderRadius: '8px',
  },
  HOLD: {
    background: '#f0a63b',
    color: 'white',
  },
  RETRY: {
    background: '#61d395',
    color: 'white',
  },
  SRN: {
    background: '#ffe1e5',
    color: '#fa677b',
  },
}));
